import React from "react";
import { Slide } from "react-reveal";
import { Element } from "react-scroll";
import "./whychooseus.css";

export const Whychooseus = (props) => {
  return (
    <>
      <div className="section-title text-center" id="services">
        <h2>WHY CHOOSE US ?</h2>
      </div>
      <ul className="grid">
        <Slide bottom >
          <li className="grid__item">
            <div className="card card--primary">
              <div className="card__top">
                <figure className="card__figure">
                  <img
                    className="card__image"
                    src="https://res.cloudinary.com/dlzatvbqe/image/upload/v1695811209/Website/Why%20Choose%20Us_/CUSTOMISATION_rhelqw.png"
                    width="400"
                    height="300"
                    alt="Service 1"
                  />
                </figure>
              </div>
              <div className="card__bottom">
                <h2 className="card__title">Customization</h2>
                <p className="card__text">
                  We understand that every home is unique, and so are its
                  needs. Our modular solutions are tailored to suit your
                  preferences, lifestyle, and space requirements. Whether you're
                  a culinary enthusiast or a fashion forward individual, our
                  designs reflect your personality.
                </p>
              </div>
            </div>
          </li>
        </Slide>
        <Slide bottom >
          <li className="grid__item">
            <div className="card card--secondary card--reverse">
              <div className="card__top">
                <figure className="card__figure">
                  <img
                    className="card__image"
                    src="https://res.cloudinary.com/dlzatvbqe/image/upload/v1695906108/Website/Why%20Choose%20Us_/QUALITY_CRAFTSMANSHIP_cixhih.png"
                    width="400"
                    height="100"
                    alt="Service 2"
                  />
                </figure>
              </div>
              <div className="card__bottom">
                <h2 className="card__title">Quality Craftsmanship</h2>
                <p className="card__text">
                  We take immense pride in the quality of our work. Our
                  products are crafted using the finest materials, ensuring
                  durability, longevity, and a touch of luxury. Our modular
                  kitchens and wardrobes stand the test of time, remaining
                  stunning even after years of use.
                </p>
              </div>
            </div>
          </li>
        </Slide>
        <Slide bottom>
          <li className="grid__item">
            <div className="card card--tertiary">
              <div className="card__top">
                <figure className="card__figure">
                  <img
                    className="card__image"
                    src="https://res.cloudinary.com/dlzatvbqe/image/upload/v1695811186/Website/Why%20Choose%20Us_/FUNCTIONAL_AESTHETICS_e1row4.png"
                    width="400"
                    height="300"
                    alt="Service 3"
                  />
                </figure>
              </div>
              <div className="card__bottom">
                <h2 className="card__title">Functional Aesthetics</h2>
                <p className="card__text">
                  We believe that functionality and aesthetics go hand in hand.
                  Our designs not only exude elegance but also enhance
                  usability. Each element is carefully planned to provide
                  convenience, organization, and a clutter-free environment.
                </p>
              </div>
            </div>
          </li>
        </Slide>
        <Slide bottom >
          <li className="grid__item">
            <div className="card card--quaternary card--reverse">
              <div className="card__top">
                <figure className="card__figure">
                  <img
                    className="card__image"
                    src="https://res.cloudinary.com/dlzatvbqe/image/upload/v1695811192/Website/Why%20Choose%20Us_/CUSTOMER-CENTRIC_APPROACH_u64m36.png"
                    width="400"
                    height="100"
                    alt="Service 4"
                  />
                </figure>
              </div>
              <div className="card__bottom">
                <h2 className="card__title">Customer-Centric Approach</h2>
                <p className="card__text">
                  Your satisfaction is our driving force. We work closely with
                  you at every step, from the initial consultation to the final
                  installation. Your inputs and feedback are invaluable to us,
                  ensuring that the end result is a true reflection of your
                  vision.
                </p>
              </div>
            </div>
          </li>
        </Slide>
        <Slide bottom>
          <li className="grid__item">
            <div className="card card--quinary">
              <div className="card__top">
                <figure className="card__figure">
                  <img
                    className="card__image"
                    src="https://res.cloudinary.com/dlzatvbqe/image/upload/v1695811197/Website/Why%20Choose%20Us_/INNOVATION_euskyv.png"
                    width="400"
                    height="300"
                    alt="Service 5"
                  />
                </figure>
              </div>
              <div className="card__bottom">
                <h2 className="card__title">Innovation</h2>
                <p className="card__text">
                  Keeping up with the latest trends and technologies, we infuse
                  innovation into every project. From smart storage solutions to
                  state of the art fittings, our designs are at the forefront
                  of modern living.
                </p>
              </div>
            </div>
          </li>
        </Slide>
      </ul>
    </>
  );
};
