import React, { useState, useEffect } from "react";
import { Slide } from "react-reveal";
import { Element } from "react-scroll";
import "./slides.css";

export const Slides = (props) => {
  const images = [
    [
      "https://res.cloudinary.com/dlzatvbqe/image/upload/v1696451547/Website/New%20folder/RUSTIC_1_zzayxj.jpg",
      "https://res.cloudinary.com/dlzatvbqe/image/upload/v1696451548/Website/New%20folder/RUSTIC_2_ynyqkn.jpg",
    ],
    [
      "https://res.cloudinary.com/dlzatvbqe/image/upload/v1696451547/Website/New%20folder/CLEAN_1_izyp9m.jpg",
      "https://res.cloudinary.com/dlzatvbqe/image/upload/v1696451547/Website/New%20folder/CLEAN_2_en13ap.jpg",
    ],
    [
      "https://res.cloudinary.com/dlzatvbqe/image/upload/v1696451548/Website/New%20folder/STYLISH_1_bqu7ng.jpg",
      "https://res.cloudinary.com/dlzatvbqe/image/upload/v1696451548/Website/New%20folder/STYLISH_2_js5r3b.jpg",
    ],
    [
      "https://res.cloudinary.com/dlzatvbqe/image/upload/v1696451547/Website/New%20folder/ANTIQUE_1_fehirw.jpg",
      "https://res.cloudinary.com/dlzatvbqe/image/upload/v1696451547/Website/New%20folder/ANTIQUE_2_hltccb.jpg",
    ],
    [
      "https://res.cloudinary.com/dlzatvbqe/image/upload/v1696451548/Website/New%20folder/FAMILY_1_nymaem.jpg",
      "https://res.cloudinary.com/dlzatvbqe/image/upload/v1696451547/Website/New%20folder/FAMILY_2_c1ng03.jpg",
    ],
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(
    images.map(() => 0)
  );

  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (hoveredIndex === null) {
        setCurrentImageIndex((prevIndex) =>
          prevIndex.map((idx, setIndex) =>
            (idx + 1) % images[setIndex].length
          )
        );
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [hoveredIndex]);

  return (
    <div className="site-inner" id="quality">
      <div className="container-fluid">
        {/* ACCORDION ROW */}
        <div className="row">
          <ul className="accordion-group" id="accordion">
            {images.map((imageSet, setIndex) => (
              <li
                key={setIndex}
                className={setIndex=== 1 && hoveredIndex === null ? "out" : ""}
                onMouseEnter={() => setHoveredIndex(setIndex)}
                onMouseLeave={() => setHoveredIndex(null)}
                style={{
                  backgroundImage: `url("${imageSet[currentImageIndex[setIndex]]}")`,
                }}
              >
                <div className="accordion-overlay" />
                <h3>
                  {setIndex === 0
                    ? "Rustic"
                    : setIndex === 1
                    ? "Clean"
                    : setIndex === 2
                    ? "Stylish"
                    : setIndex === 3
                    ? "Antique"
                    : setIndex === 4
                    ? "Family"
                    : ""}
                </h3>
                <section className="hidden-xs">
                  <article>
                    <p>
                      {setIndex === 0
                        ? "Embracing natural materials and a cosy, weathered look for a countryside-inspired ambience."
                        : setIndex === 1
                        ? "Achieving a serene and clutter-free atmosphere with a focus on minimalism and neutral colours."
                        : setIndex === 2
                        ? "Incorporating contemporary trends and creative elements to create a chic and modern aesthetic."
                        : setIndex === 3
                        ? "Celebrating the charm of the past with vintage furniture and decor that tells a unique story."
                        : setIndex === 4
                        ? "Designing for comfort, functionality, and a welcoming atmosphere to accommodate the needs of a household."
                        : ""}
                    </p>
                  </article>
                </section>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
