import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Import 'Routes'
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
// import { About } from "./components/about";
// import { Howitworks } from "./components/howitworks";
// import { Whychooseus } from "./components/whychooseus";
// import { Gallery } from "./components/gallery"; // Note the change here
// import { Slides } from "./components/slides";
// import { Testimonials } from "./components/testimonials";
// import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import ScrollToTopArrow from "./components/ScrollToTopArrow";
import CallNowButton from "./components/CallNowButton";
import { MoreProjects } from "./components/moreprojects";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 2000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/MoreProjects" element={<MoreProjects data={landingPageData.MoreProjects} />} />
          <Route path="/" element={<Header data={landingPageData.Header} />} />
          {/* Define other routes below */}
        </Routes>
        <ScrollToTopArrow />
        <CallNowButton />
      </div>
    </Router>
  );
};

export default App;
