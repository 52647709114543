import React, { useEffect, useState } from "react";
import "./gallery.css";
import {MoreProjects} from "./moreprojects"

export const Gallery = (props) => {
  const [showMoreProjects, setShowMoreProjects] = useState(false);

  const openMoreProjects = () => {
    // Redirect to the "More Projects" page
    window.location.href = "/MoreProjects";
  };
  useEffect(() => {
    // Add the slide-in animation to gallery images after a delay
    const images = document.querySelectorAll(".img-responsive");
    images.forEach((image, index) => {
      setTimeout(() => {
        image.classList.add("slide-in");
      }, index * 200); // Adjust the delay as needed
    });
  }, []);
  return (
    <>
     <div id="portfolio" className="text-center">
        <div className="section-title">
    <h2>Projects</h2>
    
    <div id="gallery" className="container-fluid">
      <img
        src="https://res.cloudinary.com/dbh7zteku/image/upload/v1697462958/MOD_WEBSITE/mbr3_u8ickh.png"
        className="img-responsive slide-in"
      />
      <img
        src="https://res.cloudinary.com/dbh7zteku/image/upload/v1697462923/MOD_WEBSITE/KBR_C1_ylfpbf.jpg"
        className="img-responsive slide-in"
      />
      <img
        src="https://res.cloudinary.com/dbh7zteku/image/upload/v1697462923/MOD_WEBSITE/KBR_C2__fjrqn4.jpg"
        className="img-responsive slide-in"
      />
      {/* <video className="vid" controls="">
        <source
          src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
          type="video/mp4"
        />
      </video> */}
      <img
        src="https://res.cloudinary.com/dbh7zteku/image/upload/v1697462922/MOD_WEBSITE/MBR_C1._ip6dhb.jpg"
        className="img-responsive slide-in"
      />
      <img
        src="https://res.cloudinary.com/dbh7zteku/image/upload/v1697462916/MOD_WEBSITE/IMG-20190727-WA0003_a2idiw.jpg"
        className="img-responsive slide-in"
      />
      <img
        src="https://res.cloudinary.com/dbh7zteku/image/upload/v1697462916/MOD_WEBSITE/mbr_opt1_vktryi.jpg"
        className="img-responsive slide-in"
      />
      <img
        src="https://res.cloudinary.com/dbh7zteku/image/upload/v1697462916/MOD_WEBSITE/IMG-20190727-WA0006_sz3m9e.jpg"
        className="img-responsive slide-in"
      />
      <img
        src="https://res.cloudinary.com/dbh7zteku/image/upload/v1697462949/MOD_WEBSITE/kitchen2_ev6qv5.png"
        className="img-responsive slide-in"
      />
      <img
        src="https://res.cloudinary.com/dbh7zteku/image/upload/v1697462956/MOD_WEBSITE/living3_oh6pl3.png"
        className="img-responsive slide-in"
      />
      <img
        src="https://res.cloudinary.com/dbh7zteku/image/upload/v1697462922/MOD_WEBSITE/TV_UNIT_C1__tydbl0.jpg"
        className="img-responsive slide-in"
      />
      {/* <img
        src="https://res.cloudinary.com/dlzatvbqe/image/upload/v1695811690/gallery/Ayush%20SE/dining_mwpdwb.png"
        className="img-responsive slide-in"
      />
      <img
        src="https://res.cloudinary.com/dlzatvbqe/image/upload/v1695811523/gallery/Anup%20AV/living2_mw5sqy.jpg"
        className="img-responsive slide-in"
      />
      <img
        src="https://res.cloudinary.com/dlzatvbqe/image/upload/v1695811642/gallery/Naveen%20_%20Sowjanya%20GRRP/KTN_C2_exklex.jpg"
        className="img-responsive slide-in"
      />
      <img
        src="https://res.cloudinary.com/dlzatvbqe/image/upload/v1695811648/gallery/Naveen%20_%20Sowjanya%20GRRP/KTN_C1__hesnxm.jpg"
        className="img-responsive slide-in"
      />
      <img
        src="https://res.cloudinary.com/dlzatvbqe/image/upload/v1695811594/gallery/Ayush%20BLF/living1_fisl5w.png"
        className="img-responsive slide-in"
      />
      <img
        src="https://res.cloudinary.com/dlzatvbqe/image/upload/v1695812297/gallery/Rahul%20_%20Shivika/IMG_20191122_200636_ecawel.jpg"
        className="img-responsive slide-in"
      /> */}
      {/* <img
        src="./img/gallery/Prestige Falcon City/WhatsApp Image 2020-06-01 at 16.07.48.jpeg"
        className="img-responsive"
      /> */}
    </div>
    </div>
    <button onClick={openMoreProjects} className="btn btn-custom btn-lg page-scroll">For More Projects</button>
    </div>
    {showMoreProjects && <MoreProjects />} {/* Conditionally render MoreProjects component */}
  </>  
  );
};
