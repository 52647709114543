import React from "react";
import { Slide } from "react-reveal";
import { Element } from "react-scroll";
import "./howitworks.css";

export const Howitworks = (props) => {
  return (
    <div className="site-inner" id="howitworks">
    <div className="container-fluid">
      {/* BEGIN NEW ROW AFTER ACCORDION */}
      <div>
        {/* FIRST ROW */}
        <div className="container " >
        <div className="section-title">
          <h2 className="text-center section-title">How It Works ?</h2>
          {/* <h2 className="text-center ">How It Works</h2> */}
          {/* <div className="row"> */}
          <div class="timeline-main">
            <div class="timeline-wrap">
              <div class="timeline-card">
                <div class="timeline-card-wrap">
                  <div class="card-head-wrap">
                    <h3 class="timeline-card-head">Assessment:</h3>
                    {/* <h6 class="timeline-card-subhead">STEP - 1</h6> */}
                  </div>
                  <p class="timeline-card-text">
                  We begin by understanding the client's preferences, budget, and timeline through discussions and on-site assessments.
                  </p>
                </div>
              </div>
              <div class="timeline-card">
                <div class="timeline-card-wrap">
                  <div class="card-head-wrap">
                    <h3 class="timeline-card-head">Design and Planning:</h3>
                    {/* <h6 class="timeline-card-subhead">STEP - 2</h6> */}
                  </div>
                  <p class="timeline-card-text">
                  Our focus is on developing design concepts, finalizing comprehensive plans and budgets and ensuring alignment with the client's vision.
                  </p>
                </div>
              </div>
              <div class="timeline-card">
                <div class="timeline-card-wrap">
                  <div class="card-head-wrap">
                    <h3 class="timeline-card-head">Procurement and Execution:</h3>
                    {/* <h6 class="timeline-card-subhead">STEP - 3</h6> */}
                  </div>
                  <p class="timeline-card-text">
                  We source materials, manage construction, and coordinate with contractors, always mindful of quality and budget.
                  </p>
                </div>
              </div>
              <div class="timeline-card">
                <div class="timeline-card-wrap">
                  <div class="card-head-wrap">
                    <h3 class="timeline-card-head">Project Completion:</h3>
                    {/* <h6 class="timeline-card-subhead">STEP - 4</h6> */}
                  </div>
                  <p class="timeline-card-text">
                  Our responsibilities include styling the space, handling finances, and actively seeking client feedback to enhance our reputation and drive referrals.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
        </div>
      </div>      
    </div>
  </div>
  
  );
};

