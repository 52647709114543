import React from "react";
import { About } from "./about";
import { Howitworks } from "./howitworks";
import { Whychooseus } from "./whychooseus";
import { Gallery } from "./gallery";
import { Slides } from "./slides";
import { Testimonials } from "./testimonials";
import { Contact } from "./contact";
import { Navigation } from "./navigation";

export const Header = (props) => {
  // Determine whether it's a mobile screen based on window.innerWidth
  // const isMobileScreen = window.innerWidth < 768;

  return (
    <>
    <Navigation />
    <header id="header">
      <div className="slider-container">
        
        {/* Desktop layout */}
        {/* {!isMobileScreen && ( */}
          <div className="ba-slider">
            <img src="https://res.cloudinary.com/dlzatvbqe/image/upload/v1695840152/Website/Landing%20Page/Copy_of_abr1_mfvjiu.png" alt="Image 1"/>
            <div className="resize">
              <img src="https://res.cloudinary.com/dlzatvbqe/image/upload/v1695840154/Website/Landing%20Page/Copy_of_kitchen1_qnkw99.png" alt="Image 2"/>
            </div>
            <span className="handle"></span>
          </div>
        {/* )} */}

        {/* Mobile layout
        {isMobileScreen && (
          <div className="mobile-content">
            <img src="https://res.cloudinary.com/dlzatvbqe/image/upload/v1695840154/Website/Landing%20Page/Copy_of_kitchen1_qnkw99.png" alt="Mobile Image" />
            <h1 className="bold-text">Your Bold Text</h1>
          </div>
        )} */}
      </div>
    </header>
    <About/>
    <Howitworks/>
    <Whychooseus/>
    <Gallery/>
    <Slides/>
    <Testimonials/>
    <Contact/>
    </>
  );
};


// import React from "react";

// export const Header = (props) => {
//   return (
//     <header id="header">
//       <div className="intro">
//         <div className="overlay">
//           <div className="container">
//             <div className="row">
//               <div className="col-md-8 col-md-offset-2 intro-text">
//                 <h1>
//                   {props.data ? props.data.title : "Loading"}
//                   <span></span>
//                 </h1>
//                 <p>{props.data ? props.data.paragraph : "Loading"}</p>

//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </header>
//   );
// };