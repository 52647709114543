import React from 'react';
import './CallNowButton.css';

const CallNowButton = () => {
  return (
    <>
  <a href="tel:+91 78299 23262" mypage="" className="call-now" rel="nofollow">
    <div className="mypage-alo-phone">
      <div className="animated infinite zoomIn mypage-alo-ph-circle" />
      <div className="animated infinite pulse mypage-alo-ph-circle-fill" />
      <div className="animated infinite tada mypage-alo-ph-img-circle" />
    </div>
  </a>
</>

  );
};

export default CallNowButton;
