import React, { useState, useEffect } from "react";
import "./testimonials.css";

const loremIpsumData = [
  {
    name: "Ayush Nandkeolyar",
    img: "https://placekitten.com/100/100",
    text: "Got our entire home interiors done by Mahesh and team. Great finish and quality of materials used. Mahesh was very responsive and went above and beyond, they agreed to make changes as and when needed and handled the entire process smoothly even during the testing times of Covid. Most Importantly, he managed all of it without any supervision from our end as we were in another city during the entire process and we were updated regularly through photos and videos. Also, an appreciation to their design team who came up with creative ideas and made sure the outcome reflected exactly what was in our minds."
  },
  {
    name: "Naveen Velagala",
    img: "https://placekitten.com/100/100",
    text: "I heard about Modeasy from my neighbour and after checking with lot of other big players in the market I finally selected them for my interiors. It was fantastic experience with Modeasy right from design phase till the handover in terms of quality, budget, options, reach-ability and what not. Thank you Bindu, Sanju, Mahesh and Mohan who were involved in the project, I must mention that Mahesh who is our project manager came up with great ideas and suggestions during execution phase, he is gentle, friendly and approachable. I must thank you Mahesh for not giving up in getting the 3D wallpaper for kids room, my kids were delighted after seeing their room. All the interiors came out so well and better than expected. Polish for stone cladding and headboard got delayed a bit. Overall it was fabulous and I look forward to working with you guys again."
  },
  {
    name: "Anirudh Shastry",
    img: "https://placekitten.com/100/100",
    text: "I had the pleasure of working with Modeasy, and I couldn't be happier with the results. From the initial consultation to the final reveal, their team exhibited professionalism and creativity. They took the time to understand my style and needs, and the design they created exceeded my expectations. The attention to detail was impeccable, and they transformed my space into a beautiful, functional, and inviting environment. Communication throughout the project was excellent, and they kept me informed every step of the way. The project was completed on time and within budget, which was greatly appreciated. I highly recommend Modeasy for anyone looking to elevate their living space. Their expertise and passion for design truly shine through in their work. Thank you, Modeasy, for making my home a place I love to be in!"
  },
];

export const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((currentIndex + 1) % loremIpsumData.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((currentIndex - 1 + loremIpsumData.length) % loremIpsumData.length);
  };

  useEffect(() => {
    const interval = setInterval(nextTestimonial, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex]);

  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>What our clients say</h2>
        </div>
        <div className="row">
        <div className="slider">
          <button className="slider-button" onClick={prevTestimonial}>
            &lt;
          </button>
          {loremIpsumData.map((d, i) => (
            <div
              key={`${d.name}-${i}`}
              className={`testimonial ${i === currentIndex ? "active" : ""}`}
            >
              {/* <div className="testimonial-image">
                <img src={d.img} alt="" />
              </div> */}
              <div className="testimonial-content">
                <p>“{d.text}“</p>
                <div className="testimonial-meta"> - {d.name} </div>
              </div>
            </div>
          ))}
          <button className="slider-button" onClick={nextTestimonial}>
            &gt;
          </button>
        </div>
        </div>
      </div>
    </div>
  );
};
