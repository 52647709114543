import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
      <div className="text-center">
        <div className="section-title">
    <h2>About Us</h2>
        <div className="row mg">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="https://res.cloudinary.com/dlzatvbqe/image/upload/v1695811193/Website/About%20Us/Copy_of_living2_ueocof.png" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              
              <p>Ready to transform your living spaces? Begin your journey with MODEASY. Explore our portfolio, get inspired by our designs, and envision the possibilities. When you're ready, our team is here to guide you through the process, offering expert advice and turning your dreams into reality. Discover the perfect harmony of form and function with MODEASY. Contact us today to schedule a consultation and take the first step towards your dream modular kitchen and wardrobe. Your home's transformation awaits!</p>
              {/* <h3>Why Choose Us?</h3>
              <div className="list-style">
              <div>
                <ul>
                {props.data
                  ? props.data.Why.map((d, i) => {
                      const parts = d.split(":");
                      return (
                        <li key={`${d}-${i}`}>
                          <strong>{parts[0]}</strong>: {parts[1]}
                        </li>
                      );
                    })
                  : "loading"}
                </ul>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
    </div>
  );
};
