import React, { useState, useEffect } from 'react';
import './ScrollToTopArrow.css';

const ScrollToTopArrow = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;
    const scrollPosition = window.scrollY;
  
    if (scrollPosition > scrollHeight - windowHeight * 2) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };
  

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div className={`scroll-to-top visible`} onClick={scrollToTop}>
      <div className="custom-arrow">▲</div>
    </div>
  );
};

export default ScrollToTopArrow;
