import { useState } from "react";
import React from "react";
import emailjs from 'emailjs-com';

export const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    PropertyType: "",
    Bedrooms: "",
    Budget: "",
    Location: "",
    Requirements: ""
  });  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("formData",formData)
    emailjs
      .send('service_n41qxgj', 'template_5jaa7m1', formData, 'wi6hnyt8bqopIhN3I')
      .then(
        function (response) {
          console.log('Email sent successfully:', response);
          // Reset the form fields
          setFormData({
            name: '',
            email: '',
            contact: '',
            PropertyType: '',
            Bedrooms: '',
            Budget: '',
            Location: '',
            Requirements: '',
          });
        },
        function (error) {
          console.error('Error sending email:', error);
        }
      );
  };
  const handleDropdownChange = (fieldName, selectedValue) => {
    setFormData({
      ...formData,
      [fieldName]: selectedValue,
    });
  };

  const propertyTypeOptions = ["House", "Apartment", "Condo", "Other"];
  const bedroomsOptions = ["1 Bedroom", "2 Bedrooms", "3 Bedrooms", "4+ Bedrooms"];

  return (
    <div>
       <div className="main"id="contact">
      <div className="footer" >
        <div className="content">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        value={formData.name}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        value={formData.email}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="tel"
                        id="contact"
                        name="contact"
                        className="form-control"
                        placeholder="Contact No"
                        required
                        value={formData.contact}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="PropertyType"
                        name="PropertyType"
                        className="form-control"
                        placeholder="Property Type"
                        required
                        value={formData.PropertyType}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="bedrooms" 
                        name="Bedrooms"
                        className="form-control"
                        placeholder="No. of Bedrooms"
                        required
                        value={formData.Bedrooms}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="Budget"
                        name="Budget"
                        className="form-control"
                        placeholder="Budget"
                        required
                        value={formData.Budget}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="Property Location"
                        name="Location"
                        className="form-control"
                        placeholder="Property Location"
                        required
                        value={formData.Location}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="Requirements"
                    id="Requirements"
                    className="form-control"
                    rows="4"
                    placeholder="Requirements"
                    required
                    value={formData.Requirements}
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
          {/* <img src="./logo.png" alt="Logo" width="240px"/> */}
          {/* <img src="./logo.png" alt="Logo" width="240px"/> */}
            <div className="contact-item">
              <h3><b>Contact Info</b></h3>
              <p>
                <span>
                <b><i className="fa fa-map-marker"></i> Address</b>
                </span>
                No. 39, Saiparisiddh, Royal Palms Layout,<br/> Next to Mantri Tranquil, Gubbalala, <br/> Bengaluru - 560061
              </p>
                <img src="./img/QR.png" alt="OR scanner" width="100px" style={{marginLeft:"100px"}}/>
            </div>
                <a href="https://maps.app.goo.gl/DyhYVgoMP4MRotgv6" target="_blank">https://maps.app.goo.gl/DyhYVgoMP4MRotgv6</a>
            <div className="contact-item">
              <p>
                <span>
                <b><i className="fa fa-phone"></i> Phone</b>
                </span>{" "}
                <a href="tel:+917829923262">+91 78299 23262</a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                <b><i className="fa fa-envelope-o"></i> Email</b>
                </span>{" "}
                Modeasy.in@gmail.com
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href="https://instagram.com/modeasy.in?igshid=MmU2YjMzNjRlOQ==" target="_blank">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/modeasy-in/" target="_blank">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://youtube.com/@Modeasy?si=qufwIrlqOCl5Dx1l" target="_blank">
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> 
        </div>
        <p id="footer">
            &copy; 2023 designed by {" "}
            <a href="http://www.modeasy.in" rel="nofollow">
            Modeasy.in
            </a>
          </p>
        </div>
      </div>         
      </div> 
    </div>
  );
};


// import { useState } from "react";
// import React from "react";
// import { useForm, ValidationError } from '@formspree/react';

// const initialState = {
//   name: "",
//   email: "",
// };
// export const Contact = (props) => {
//   const [formData, setFormData] = useState(initialState);
//   const [state, handleSubmit] = useForm('mbjvqgjr'); // Replace 'your-formspree-endpoint' with your Formspree endpoint
//   if (state.succeeded) {
//     return <p>Thanks for submitting the form!</p>;
//   }

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevState) => ({ ...prevState, [name]: value }));
//   };

//   // const handleSubmit = (e) => {
//   //   e.preventDefault();
//   //   console.log("Form Data:", formData);
//   // };
//   return (
//     <div>
//        <div className="main"id="contact">
//       <div className="footer" >
//       <div className="bubbles">
//             {Array.from({ length: 128 }).map((_, i) => (
//               <div
//                 key={i}
//                 className="bubble"
//                 style={{
//                   "--size": `${2 + Math.random() * 4}rem`,
//                   "--distance": `${6 + Math.random() * 4}rem`,
//                   "--position": `${-5 + Math.random() * 110}%`,
//                   "--time": `${2 + Math.random() * 2}s`,
//                   "--delay": `${-1 * (2 + Math.random() * 2)}s`,
//                 }}
//               ></div>
//             ))}
//           </div>
//         <div className="content">
//           <div>
//           <div className="container">
//           <div className="col-md-8">
//             <div className="row">
//               <div className="section-title">
//                 <h2>Get In Touch</h2>
//                 <p>
//                   Please fill out the form below to send us an email and we will
//                   get back to you as soon as possible.
//                 </p>
//               </div>
//               <form onSubmit={handleSubmit}>
//       <div className="row">
//         <div className="col-md-6">
//           <div className="form-group">
//             <label htmlFor="name">Name</label>
//             <input
//               type="text"
//               id="name"
//               name="name"
//               className="form-control"
//               placeholder="Name"
//             />
//             <ValidationError
//               prefix="Name"
//               field="name"
//               errors={state.errors}
//             />
//           </div>
//         </div>
//         <div className="col-md-6">
//           <div className="form-group">
//             <label htmlFor="email">Email</label>
//             <input
//               type="email"
//               id="email"
//               name="email"
//               className="form-control"
//               placeholder="Email"
//             />
//             <ValidationError
//               prefix="Email"
//               field="email"
//               errors={state.errors}
//             />
//           </div>
//         </div>
//         <div className="col-md-6">
//           <div className="form-group">
//             <label htmlFor="contact">Contact No</label>
//             <input
//               type="tel"
//               id="contact"
//               name="contact"
//               className="form-control"
//               placeholder="Contact No"
//             />
//             <ValidationError
//               prefix="Contact No"
//               field="contact"
//               errors={state.errors}
//             />
//           </div>
//         </div>
//         <div className="col-md-6">
//           <div className="form-group">
//             <label htmlFor="PropertyType">Property Type</label>
//             <input
//               type="text"
//               id="PropertyType"
//               name="PropertyType"
//               className="form-control"
//               placeholder="Property Type"
//             />
//             <ValidationError
//               prefix="Property Type"
//               field="PropertyType"
//               errors={state.errors}
//             />
//           </div>
//         </div>
//         <div className="col-md-6">
//           <div className="form-group">
//             <label htmlFor="Bedrooms">No. of Bedrooms</label>
//             <input
//               type="text"
//               id="Bedrooms"
//               name="Bedrooms"
//               className="form-control"
//               placeholder="No. of Bedrooms"
//             />
//             <ValidationError
//               prefix="No. of Bedrooms"
//               field="Bedrooms"
//               errors={state.errors}
//             />
//           </div>
//         </div>
//         <div className="col-md-6">
//           <div className="form-group">
//             <label htmlFor="Budget">Budget</label>
//             <input
//               type="text"
//               id="Budget"
//               name="Budget"
//               className="form-control"
//               placeholder="Budget"
//             />
//             <ValidationError
//               prefix="Budget"
//               field="Budget"
//               errors={state.errors}
//             />
//           </div>
//         </div>
//         <div className="col-md-6">
//           <div className="form-group">
//             <label htmlFor="Location">Property Location</label>
//             <input
//               type="text"
//               id="Location"
//               name="Location"
//               className="form-control"
//               placeholder="Property Location"
//             />
//             <ValidationError
//               prefix="Property Location"
//               field="Location"
//               errors={state.errors}
//             />
//           </div>
//         </div>
//       </div>
//       <div className="form-group">
//         <label htmlFor="Requirements">Requirements</label>
//         <textarea
//           name="Requirements"
//           id="Requirements"
//           className="form-control"
//           rows="4"
//           placeholder="Requirements"
//         />
//         <ValidationError
//           prefix="Requirements"
//           field="Requirements"
//           errors={state.errors}
//         />
//       </div>
//       <div id="success"></div>
//       <button type="submit" className="btn btn-custom btn-lg">
//         Send Message
//       </button>
//     </form>
//             </div>
//           </div>
//           <div className="col-md-3 col-md-offset-1 contact-info">
//           <img src="./logo.png" alt="Logo" width="240px"/>
//           {/* <img src="./logo.png" alt="Logo" width="240px"/> */}
//             <div className="contact-item">
//               <h3><b>Contact Info</b></h3>
//               <p>
//                 <span>
//                 <b><i className="fa fa-map-marker"></i> Address</b>
//                 </span>
//                 {props.data ? props.data.address : "loading"}
//               </p>
//                 <img src="./img/QR.png" alt="GMAP" width="100px" style={{marginLeft:"100px"}}/>
//             </div>
//                 <a href="https://maps.app.goo.gl/6GGBhpBjjBi9UvdEA" target="_blank">https://maps.app.goo.gl/6GGBhpBjjBi9UvdEA</a>
//             <div className="contact-item">
//               <p>
//                 <span>
//                 <b><i className="fa fa-phone"></i> Phone</b>
//                 </span>{" "}
//                 {props.data ? props.data.phone : "loading"}
//               </p>
//             </div>
//             <div className="contact-item">
//               <p>
//                 <span>
//                 <b><i className="fa fa-envelope-o"></i> Email</b>
//                 </span>{" "}
//                 {props.data ? props.data.email : "loading"}
//               </p>
//             </div>
//           </div>
//           <div className="col-md-12">
//             <div className="row">
//               <div className="social">
//                 <ul>
//                   <li>
//                     <a href={props.data ? props.data.instagram : "/"}target="_blank">
//                       <i className="fa fa-instagram"></i>
//                     </a>
//                   </li>
//                   <li>
//                     <a href={props.data ? props.data.linkedin : "/"} target="_blank">
//                       <i className="fa fa-linkedin"></i>
//                     </a>
//                   </li>
//                   <li>
//                     <a href={props.data ? props.data.youtube : "/"} target="_blank">
//                       <i className="fa fa-youtube"></i>
//                     </a>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
          
//         </div>
//         <p id="footer">
//             &copy; 2023 designed by {" "}
//             <a href="http://www.modeasy.in" rel="nofollow">
//             Modeasy.in
//             </a>
//           </p>
//           </div>
//         </div>
//       </div>
//       <svg style={{ position: "fixed", top: "100vh" }}>
//         <defs>
//           <filter id="blob">
//             <feGaussianBlur
//               in="SourceGraphic"
//               stdDeviation="10"
//               result="blur"
//             />
//             <feColorMatrix
//               in="blur"
//               mode="matrix"
//               values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
//               result="blob"
//             />
//           </filter>
//         </defs>
//       </svg>          
//       </div> 
//     </div>
//   );
// };
