import React, { useState, useRef, useEffect } from "react";
import { Navigation } from "./navigation";
import "./moreprojects.css";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export const MoreProjects = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', content: '' });

  const modalRef = useRef();

  useEffect(() => {
    const closeModalOnClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    if (isModalOpen) {
      document.addEventListener("click", closeModalOnClickOutside);
    } else {
      document.removeEventListener("click", closeModalOnClickOutside);
    }

    return () => {
      document.removeEventListener("click", closeModalOnClickOutside);
    };
  }, [isModalOpen]);

  const openModal = (title, content, images) => {
    setIsModalOpen(true);
    setModalContent({ title, content, images });
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Sample data for the cards
  const cardData = [
    {
      images: [
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475994/Ayush%20SE/mbr3_gu9vim.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475994/Ayush%20SE/without_dining_gmikrf.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475994/Ayush%20SE/living3_vau3yv.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475993/Ayush%20SE/abr1_nhk6y3.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475993/Ayush%20SE/living2_ocbovg.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475993/Ayush%20SE/mbr1_i67ej8.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475992/Ayush%20SE/living1_lnsvrx.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475992/Ayush%20SE/gbr3_ceiucu.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475992/Ayush%20SE/mbr2_jcfdy1.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475992/Ayush%20SE/abr2_rmoujr.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475991/Ayush%20SE/gbr1_ymxchz.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475991/Ayush%20SE/kitchen1_tsk6wn.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475991/Ayush%20SE/kitchen2_getfk5.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475990/Ayush%20SE/crockery_rsswfr.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475990/Ayush%20SE/gbr2_ljs7pp.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475990/Ayush%20SE/foyer2_ud7s0d.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475988/Ayush%20SE/abr3_tgls8f.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475988/Ayush%20SE/dining_dlnoyx.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475988/Ayush%20SE/pooja_ywpzce.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475987/Ayush%20SE/foyer1_tnuzij.png",

      ],
      title: "Ayush SE",
      subtitle: "Lorem ipsum dolor set amet, some dummy content...",
      content: "Description for Card 1",

    },
    {
      images: [
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475985/Laksha/mbr_side_table_p3fkhw.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475976/Laksha/TV_UNIT_dtzx1p.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475976/Laksha/mbr_opt1_nzrypv.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475976/Laksha/kitchen_gd4nny.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475975/Laksha/gbr5_iuxqfa.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475975/Laksha/mbr_dresser_izzowo.jpg",
      ],
      title: "Laksha EE",
      subtitle: "Lorem ipsum dolor set amet, some dummy content...",
      content: "Description for Card 2",

    },
    {
      images: [
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475989/Naveen/FOYER_C1__fcldfe.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475986/Naveen/TV_UNIT_C1__g5mueh.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475986/Naveen/MBR_C1._fvp4u5.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475986/Naveen/KBR_C1_a6x61k.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475985/Naveen/POOJA_C1__afeyqs.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475979/Naveen/KBR_C2__glqd9i.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475979/Naveen/GBR_C1__bc5ais.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475979/Naveen/MBR_C2._v0kzxl.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475978/Naveen/KTN_C1__ibkyat.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475978/Naveen/KTN_C2_esqpgg.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475977/Naveen/LIVING_C1_p6iutr.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475977/Naveen/KBR_C3__sbsktl.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475977/Naveen/GBR_C2_njdadv.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475976/Naveen/KTN_C3_c45lgh.jpg",
      ],
      title: "Naveen & Soujanya",
      subtitle: "Lorem ipsum dolor set amet, some dummy content...",
      content: "Description for Card 3",

    },
    {
      images: [
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475997/Aditya/Copy_of_Photo_from_Modeasy_2_mz6bwl.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475996/Aditya/Copy_of_Photo_from_Modeasy_1_mxgoyr.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475996/Aditya/Copy_of_Photo_from_Modeasy_4_uges45.jpg",
      ],
      title: "Adithya",
      subtitle: "Lorem ipsum dolor set amet, some dummy content...",
      content: "Description for Card 4",

    },
    // {
    //   images: [
    //     "https://res.cloudinary.com/dlzatvbqe/image/upload/v1695811600/gallery/Prajna%20_%20Vinuth/foyer_mytfvq.png",
    //     "https://res.cloudinary.com/dlzatvbqe/image/upload/v1695811600/gallery/Prajna%20_%20Vinuth/foyer_mytfvq.png",
    //     "https://res.cloudinary.com/dlzatvbqe/image/upload/v1695811600/gallery/Prajna%20_%20Vinuth/foyer_mytfvq.png",
    //     "https://res.cloudinary.com/dlzatvbqe/image/upload/v1695811388/gallery/Aditya%20P/Copy_of_Photo_from_Modeasy_2_sh3qhx.jpg",
    //   ],
    //   title: "Pankaj",
    //   subtitle: "Lorem ipsum dolor set amet, some dummy content...",
    //   content: "Description for Card 5",

    // },
    {
      images: [
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475941/Shivika/IMG_20191123_204844_pvo8jp.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475940/Shivika/IMG_20191122_200558_i0wf66.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475940/Shivika/IMG_20191123_204605_wsvo01.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475940/Shivika/IMG_20191123_204849_fbat4z.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475940/Shivika/IMG_20191123_204717_l4x91o.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475939/Shivika/IMG_20191123_204830_mwldwk.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475939/Shivika/IMG_20191123_204553_uwgb4y.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475939/Shivika/IMG_20191122_200753_pd8p4v.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475938/Shivika/IMG_20191122_200526_fgcsoj.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475938/Shivika/IMG_20191123_204737_wzfw60.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475938/Shivika/IMG_20191123_204701_cwtjrx.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475938/Shivika/IMG_20191122_200600_uxvphv.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475938/Shivika/IMG_20191122_200532_dsdtnl.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475937/Shivika/IMG_20191122_200334_czrhuk.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475937/Shivika/IMG_20191122_200636_a8trg1.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475937/Shivika/IMG_20191122_200846_tto5ig.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475937/Shivika/IMG_20191122_201217_bfufii.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475937/Shivika/IMG_20191122_201200_adimlg.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475934/Shivika/IMG-20191108-WA0005_v6qk9k.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475936/Shivika/IMG_20191122_200859_sc06jx.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475934/Shivika/IMG-20191108-WA0004_yb1teq.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475934/Shivika/IMG-20191203-WA0011_boggyq.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475934/Shivika/IMG-20191108-WA0003_nyg9je.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475933/Shivika/IMG-20191203-WA0010_mgggea.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475933/Shivika/IMG-20191203-WA0014_ju3u6v.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475933/Shivika/IMG-20191203-WA0012_rlczrj.jpg",
      ],
      title: "Rahul & Shvika",
      subtitle: "Lorem ipsum dolor set amet, some dummy content...",
      content: "Description for Card 6",

    },
    {
      images: [
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475933/Taksh/IMG-20190727-WA0006_v15lms.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475933/Taksh/IMG-20190727-WA0005_rlcwit.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475933/Taksh/IMG-20190727-WA0004_zmp9b5.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475933/Taksh/IMG-20190727-WA0003_blpnul.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475932/Taksh/IMG-20190727-WA0002_y5cfha.jpg",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475932/Taksh/IMG-20190727-WA0001_li59gc.jpg",
        
      ],
      title: "Taksh",
      subtitle: "Lorem ipsum dolor set amet, some dummy content...",
      content: "Description for Card 7",

    },
    {
      images: [
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698477627/Ayush%20BLF/living2_f0dlsg.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698477626/Ayush%20BLF/living1_grjwmv.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698477625/Ayush%20BLF/mbr2_qqqrtl.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698477624/Ayush%20BLF/kitchen2_o3a1ck.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698477624/Ayush%20BLF/kitchen1_ocfgas.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698477623/Ayush%20BLF/foyer_qijqaj.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698477623/Ayush%20BLF/mbr1_y8pvxg.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698477622/Ayush%20BLF/bedroom2_1_beige_qu76gp.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698477621/Ayush%20BLF/bedroom2_1_beige2_h1qy80.png",
        
      ],
      title: "Ayush BLF",
      subtitle: "Lorem ipsum dolor set amet, some dummy content...",
      content: "Description for Card 8",

    },
    {
      images: [
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475978/Manjunath/2_ydnob7.png",
        "https://res.cloudinary.com/dbh7zteku/image/upload/v1698475978/Manjunath/1_y6m64d.png",
        
      ],
      title: "Manjunath",
      subtitle: "Lorem ipsum dolor set amet, some dummy content...",
      content: "Description for Card 9",

    },
  ];

  return (
    <>
      {/* <Navigation /> */}
      <nav id="menu" className="navbar navbar-default navbar-fixed-top navbar-decreased">
        <div className="container">
          <div className="navbar-header text-center"> {/* Center-align the content */}
          <a href="/#">
            <img src="logo.png" alt="Logo" />
            </a>
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
          </div>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a href="/" className="page-scroll">
                  Home
                </a>
              </li>
              <li>
                <a href="/#about" className="page-scroll">
                  About
                </a>
              </li>
              <li>
                <a href="/#howitworks" className="page-scroll">
                  How it works
                </a>
              </li>
              <li>
                <a href="/#portfolio" className="page-scroll">
                  Projects
                </a>
              </li>
              <li>
                <a href="/#testimonials" className="page-scroll">
                  Testimonials
                </a>
              </li>
              {/* <li>
                <a href="#team" className="page-scroll">
                  Team
                </a>
              </li> */}
              <li>
                <a href="/#contact" className="page-scroll">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="moreprojects">
        <div className="cards">
          {cardData.map((card, index) => (
            <div className={`card ${index === 6 ? 'center-card' : ''}`} key={index}>
              <div className="pic" style={{ backgroundImage: `url("${card.images[0]}")` }} />
              <div className="ttl">
                <h2 className="h2">{card.title}</h2>
                <button className="btn" onClick={() => openModal(card.title, card.content, card.images)}>
                  More Details
                </button>
              </div>
            </div>
          ))}
        </div>
        {isModalOpen && (
          <div className="modal" ref={modalRef}>
            <h2>{modalContent.title}</h2>
              <div className="sliders">
                {modalContent.images.map((image, index) => (
                  <React.Fragment key={index}>
                    <input type="radio" name="slide_switch" id={`id${index + 1}`} defaultChecked={index === 0} />
                    <label htmlFor={`id${index + 1}`}>
                      <img
                        src={image}
                        alt={`Image ${index + 1}`}
                        width={70}
                        height={70}
                      />
                    </label>
                    <img
                        src={image}
                        alt={`Image ${index + 1}`}
                      />
                  </React.Fragment>
                ))}
              </div>
            {/* <p>{modalContent.content}</p>
            <button onClick={closeModal}>Close</button> */}
          </div>
        )}
      </div>
      <p id="footer">
      &copy; 2023 designed by {" "}
      <a href="http://www.modeasy.in" rel="nofollow">
      Modeasy.in
      </a>
      </p>
    </>
  );
};
